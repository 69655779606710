<template>
    <div class="register">
        <div class="box has-padding-large">
            <h5 class="title is-5 has-text-black has-text-centered has-margin-bottom-medium">
                <figure class="image is-16x16 logo is-clickable"
                    @click="$router.push({ name: 'register' })">
                    <img src="/images/logo.png">
                </figure>
                <span v-if="isWebview"> Mobile</span>
            </h5>
            <enso-form class="form" ref="form"
                disable-state
                v-bind:path="routeResolver('registration.form')"
                @ready="initForm">
                <template v-slot:company_name="props">
                    <form-field v-bind="props" v-show="isCompany"/>
                </template>
                <template v-slot:register_button>
                    <button class="button register-button is-primary is-fullwidth"
                        type="submit"
                        v-bind:class="{ 'is-loading': form.state.loading }"
                        @click.prevent="submit()">
                        <span class="icon is-small">
                            <fa :icon="isCompany ? 'building' : 'user'"/>
                        </span>
                        <span class="is-hidden-mobile">
                            {{ i18n(action) }}
                        </span>
                        <span class="is-hidden-mobile"/>
                    </button>
                </template>
            </enso-form>
            <div class="is-clearfix"/>
            <div class="page-navigation">
                <router-link
                    :to="{ name: 'login' }"
                    class="is-pulled-right">
                    {{ i18n('Return to Login') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { focus } from '@enso-ui/directives';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { EnsoForm, FormField } from '@enso-ui/forms/bulma';
// import RevealPassword from '@enso-ui/forms/src/bulma/parts/RevealPassword.vue';

export default {
    name: 'RegistrationForm',

    components: { EnsoForm, FormField },

    directives: { focus },

    inject: {
        errorHandler: { from: 'errorHandler' },
        i18n: { from: 'i18n' },
        routeResolver: { from: 'route' },
        toastr: { from: 'toastr' },
    },

    props: {
        action: {
            default: 'Register',
            type: String,
        },
        isAccount: {
            default: false,
            type: Boolean,
        },
        isCompany: {
            default: false,
            type: Boolean,
        },
        route: {
            required: true,
            type: String,
        },
    },

    data: () => ({
        ready: false,
    }),

    computed: {
        ...mapState(['allowRegisterAccount', 'allowRegisterCompany', 'meta']),
        ...mapGetters(['isWebview']),
        allowRegisterBoth() {
            return this.allowRegisterAccount && this.allowRegisterCompany;
        },
        allowRegisterEither() {
            return this.allowRegisterAccount || this.allowRegisterCompany;
        },
        config() {
            return this.isWebview
                ? { headers: { webview: this.isWebview } }
                : {};
        },
        form() {
            return this.ready
                ? this.$refs.form.$refs.form.$refs.coreForm
                : {};
        },
    },

    methods: {
        ...mapMutations('auth', ['login']),
        ...mapMutations('layout', ['home']),
        ...mapMutations(['setCsrfToken']),
        initForm() {
            this.ready = true;
        },
        submit() {
            this.form.state.loading = true;
            axios.post(this.routeResolver(this.route), this.form.formData, this.config)
                .then(({ data }) => {
                    this.form.state.loading = false;

                    if (data.csrfToken) {
                        this.setCsrfToken(data.csrfToken);
                    }

                    setTimeout(() => {
                        this.login();
                        this.home(true);
                    }, 500);
                })
                .catch(error => {
                    const { status, data } = error.response;
                    this.form.state.loading = false;

                    if (status === 422) {
                        this.form.errors.set(data.errors);
                        this.$nextTick(this.form.focusError);
                        return;
                    }

                    this.errorHandler(error);
                });
        },
    },
};
</script>

<style lang="scss">
    .register {
        max-width: 400px;
        margin: auto;

        .actions {
            display: none;
        }

        .column {
            &.is-6,&.is-12 {
                padding: .25rem 1rem;
            }
            &.is-6:first-child {
                padding-right: .25rem;
            }
            &.is-6:last-child {
                padding-left: .25rem;
            }
            &.is-12:last-child {
                padding-top: 1.5rem;
            }
        }

        .field {
            .label {
                color: #515151;
                margin-bottom: .125rem;
            }
        }

        figure.logo {
            display: inline-block;
        }

        .page-navigation {
            border-top: 1px solid silver;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1rem;
            margin-top: 1rem;
            padding-top: 1rem;
        }
    }
</style>
