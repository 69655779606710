var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _c(
      "div",
      { staticClass: "box has-padding-large" },
      [
        _c(
          "h5",
          {
            staticClass:
              "title is-5 has-text-black has-text-centered has-margin-bottom-medium"
          },
          [
            _c(
              "figure",
              {
                staticClass: "image is-16x16 logo is-clickable",
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "register" })
                  }
                }
              },
              [_c("img", { attrs: { src: "/images/logo.png" } })]
            ),
            _vm.isWebview ? _c("span", [_vm._v(" Mobile")]) : _vm._e()
          ]
        ),
        _c("enso-form", {
          ref: "form",
          staticClass: "form",
          attrs: {
            "disable-state": "",
            path: _vm.routeResolver("registration.form")
          },
          on: { ready: _vm.initForm },
          scopedSlots: _vm._u([
            {
              key: "company_name",
              fn: function(props) {
                return [
                  _c(
                    "form-field",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isCompany,
                            expression: "isCompany"
                          }
                        ]
                      },
                      "form-field",
                      props,
                      false
                    )
                  )
                ]
              }
            },
            {
              key: "register_button",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass:
                        "button register-button is-primary is-fullwidth",
                      class: { "is-loading": _vm.form.state.loading },
                      attrs: { type: "submit" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon is-small" },
                        [
                          _c("fa", {
                            attrs: { icon: _vm.isCompany ? "building" : "user" }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "is-hidden-mobile" }, [
                        _vm._v(" " + _vm._s(_vm.i18n(_vm.action)) + " ")
                      ]),
                      _c("span", { staticClass: "is-hidden-mobile" })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        }),
        _c("div", { staticClass: "is-clearfix" }),
        _c(
          "div",
          { staticClass: "page-navigation" },
          [
            _c(
              "router-link",
              {
                staticClass: "is-pulled-right",
                attrs: { to: { name: "login" } }
              },
              [_vm._v(" " + _vm._s(_vm.i18n("Return to Login")) + " ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }